<template>
  <q-item clickable class="q-ma-sm shadow-2 rounded-borders">
    <q-item-section class="q-pa-md">
      <q-item-label class="text-primary text-subtitle1" onerliner>{{ query.name }}</q-item-label>
      <q-item-label class="text-dark">
        {{ query.description }}
      </q-item-label>
      <q-item-label v-if="tagsList" caption><strong>Tags</strong>: {{ tagsList }}</q-item-label>
    </q-item-section>
  </q-item>
</template>
<script>
export default {
  name: 'SavedQueryListItem',
  props: {
    query: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  computed: {
    tagsList () {
      return this.query.tags?.join(', ')
    }
  }
}
</script>
