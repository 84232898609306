import customers from '@/assets/json/customers'
import orders from '@/assets/json/orders'
import products from '@/assets/json/products'
import suppliers from '@/assets/json/suppliers'
import territories from '@/assets/json/territories'

import { getUuid } from '@/utils'

export const responeTables = [
  customers,
  orders,
  products,
  suppliers,
  territories
]

export const tableCount = responeTables.length

export const savedQueries = [
  {
    _id: getUuid(),
    resultId: 0,
    name: 'Customers List',
    description: 'Get details of all customers in customers table',
    tags: ['tag 1', 'tag 2'],
    query: 'CREATE VIEW vsal \n AS \n SELECT a.deptno "Department", \n a.num_emp / b.total_count "Employees", \n a.sal_sum / b.total_sal "Salary" \n FROM (SELECT deptno, \n Count() num_emp, \n SUM(sal) sal_sum \n FROM scott.emp \n WHERE city = \'NYC\' \n GROUP BY deptno) a, \n (SELECT Count() total_count, \n SUM(sal) total_sal \n FROM scott.emp \n WHERE city = \'NYC\') b ;'
  },
  {
    _id: getUuid(),
    resultId: 1,
    name: 'Orders List (big Query)',
    description: 'Get detailed list of all the orders in orders table. Result: 6000+ rows',
    tags: ['tag 1', 'tag 3'],
    query: 'INSERT ALL\n WHEN ottl < 100000 THEN\n INTO small_orders\n VALUES(oid, ottl, sid, cid)\n WHEN ottl > 100000 and ottl < 200000 THEN\n INTO medium_orders\n VALUES(oid, ottl, sid, cid)\n WHEN ottl > 200000 THEN\n into large_orders\n VALUES(oid, ottl, sid, cid)\n WHEN ottl > 290000 THEN\n INTO special_orders\n SELECT o.order_id oid, o.customer_id cid, o.order_total ottl,\n o.sales_rep_id sid, c.credit_limit cl, c.cust_email cem\n FROM orders o, customers c\n WHERE o.customer_id = c.customer_id;'
  },
  {
    _id: getUuid(),
    resultId: 2,
    name: 'Products List',
    description: 'Get all products details from products table',
    tags: ['tag 2', 'tag 5', 'tag 6'],
    query: 'SELECT \n country.country_name_eng,\n SUM(CASE WHEN call.id IS NOT NULL THEN 1 ELSE 0 END) AS calls,\n AVG(ISNULL(DATEDIFF(SECOND, call.start_time, call.end_time),0)) AS avg_difference\nFROM country \nLEFT JOIN city ON city.country_id = country.id\nLEFT JOIN customer ON city.id = customer.city_id\nLEFT JOIN call ON call.customer_id = customer.id\nGROUP BY \n country.id,\n country.country_name_eng\nORDER BY calls DESC, country.id ASC;'
  },
  {
    _id: getUuid(),
    resultId: 3,
    name: 'Suppliers List',
    description: 'Get details of all suppliers in suppliers table',
    tags: ['tag 2'],
    query: 'WITH patient_data AS (\n SELECT patient_id, patient_name, hospital, drug_dosage\n FROM hospital_registry\n WHERE (last_visit= now() - interval \'14 days\' OR last_visit IS NULL)\n AND city = "Los Angeles"\n)\nWITH average_dosage AS (\n SELECT hospital, AVG(drug_dosage) AS Average\n FROM patient_data\n GROUP BY hospital\n)\nSELECT count(hospital)\nFROM average_dosage;\nWHERE AVG(drug_dosage)= 1000'
  },
  {
    _id: getUuid(),
    resultId: 4,
    name: 'Territories List',
    description: 'Get detailed list of all the territories in territories table',
    tags: ['tag 9', 'tag 8'],
    query: 'SELECT name, second_name, date_time, address, second_address\nFROM registry, other_users_data\nWHERE registry.id = other_users_data.id;'
  }
]
