<template>
  <q-list v-if="savedQueries.length" class="q-py-sm q-mx-md non-selectable">
    <SavedQueryListItem v-for="query in savedQueries" :key="query._id" @click="runQuery(query)" :query="query" />
  </q-list>
  <div v-else class="q-pa-md text-center text-grey-8">
    No saved queries available.
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import SavedQueryListItem from '@/components/queryTabs//SavedQueryListItem'
export default {
  name: 'SavedQuery',
  components: {
    SavedQueryListItem
  },
  computed: {
    ...mapState({
      savedQueries: 'savedQueries'
    })
  },
  methods: {
    ...mapActions({
      getSelectedQueryResult: 'getSelectedQueryResult'
    }),
    runQuery (query) {
      this.getSelectedQueryResult({ selectQuery: query })
    }
  }
}
</script>
