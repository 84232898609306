<template>
  <q-table class="my-sticky-virtscroll-table full-height" virtual-scroll v-model:pagination="pagination"
    :rows-per-page-options="[0]" :virtual-scroll-sticky-size-start="48" row-key="index" :title="title" :rows="rows"
    :columns="columns" />
</template>
<script>
export default {
  name: 'QueryTable',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    rows: {
      type: Array,
      required: true,
      default: () => []
    },
    columns: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      pagination: {
        rowsPerPage: 0
      }
    }
  }
}
</script>

<style lang="scss">
.my-sticky-virtscroll-table {

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: #fff;
  }

  thead tr th {
    position: sticky;
    z-index: 1;
  }

  thead tr:last-child th {
    top: 48px;
  }

  thead tr:first-child th {
    top: 0;
  }
}
</style>
