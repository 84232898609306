<template>
  <q-card class="dialog-card">
    <q-card-section>
      <slot name="header"></slot>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <slot></slot>
    </q-card-section>
    <q-card-actions align="right" class="bg-white">
      <slot name="action"></slot>
    </q-card-actions>
  </q-card>
</template>
<script>
export default {
  name: 'PopUpDialogue'
}
</script>
