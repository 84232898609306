<template>
  <div class="full-height column no-wrap">
    <div class="q-pa-md flex justify-between">
      <div class="text-subtitle1">{{ selectedQuery.name ? selectedQuery.name : 'Query' }}</div>
      <div>
        <q-btn class="q-mx-sm" color="secondary" icon="save" @click="saveQueryPopUp" />
        <q-btn color="primary" icon="send" label="Run" @click="runQuery" />
      </div>
      <q-dialog v-model="saveQuery.isActive">
        <PopUpDialog @runSaveQuery="runSaveQuery">
          <template #header>
            <div class="text-h6">Save Query</div>
          </template>
          <q-input v-model="saveQuery.name" label="Name*" autofocus
            :rules="[val => (val && val.length > 0) || 'Field is required']" />
          <q-input v-model="saveQuery.description" label="Description" />
          <q-input v-model="saveQuery.tags" label="Tags (comma seperated)" />
          <q-input v-model="saveQuery.query" label="query" :rules="[val => val && val.length > 5]" />
          <template #action>
            <q-btn color="primary" icon="save" label="Save" @click="runSaveQuery"
              :disable="!(saveQuery.name.length > 0 && saveQuery.query.length > 5)" v-close-popup />
          </template>
        </PopUpDialog>
      </q-dialog>
    </div>
    <div class="flex-grow-2">
      <textarea v-model="content" ref="sqlEditor"></textarea>
    </div>
  </div>
</template>
<script>
import * as codeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/sql/sql'

import { getUuid, getRandom } from '@/utils'
import { tableCount } from '@/assets/mocks'

import { mapState, mapActions } from 'vuex'
import PopUpDialog from '@/components//PopUpDialog'
export default {
  name: 'NewQuery',
  data () {
    return {
      editor: null,
      saveQuery: {
        isActive: false,
        name: '',
        description: '',
        tags: '',
        query: ''
      }
    }
  },
  computed: {
    ...mapState({
      selectedQuery: 'selectedQuery'
    }),
    content () {
      return this.selectedQuery.query ? this.selectedQuery.query : 'Select * from Employee A where rownum <=8\nunion\nselect * from (Select * from Employee A order by rowid desc) where rownum <=8;'
    }
  },
  mounted () {
    this.editor = codeMirror.fromTextArea(this.$refs.sqlEditor, {
      lineNumbers: true,
      lineWrapping: true,
      mode: 'sql'
    })
  },
  methods: {
    ...mapActions({
      getQueryResult: 'getQueryResult',
      addSavedQuery: 'addSavedQuery'
    }),
    runQuery () {
      if (this.selectedQuery.query !== this.editor.doc.getValue() && this.editor.doc.getValue().length > 5) {
        this.getQueryResult({ query: this.editor.doc.getValue() })
      }
    },
    saveQueryPopUp () {
      this.saveQuery.isActive = true
      this.saveQuery.name = this.selectedQuery?.name ? this.selectedQuery.name : ''
      this.saveQuery.description = this.selectedQuery?.description ? this.selectedQuery.description : ''
      this.saveQuery.tags = this.selectedQuery?.tags?.length ? this.selectedQuery.tags.join(',') : ''
      this.saveQuery.query = this.editor.doc.getValue()
    },
    runSaveQuery () {
      this.addSavedQuery({
        saveQuery: {
          _id: getUuid(),
          name: this.saveQuery.name,
          query: this.saveQuery.query,
          tags: this.saveQuery?.tags.split(','),
          description: this.saveQuery.description,
          resultId: this.selectedQuery.resultId > -1 ? this.selectedQuery.resultId : getRandom(tableCount)
        }
      })
    }
  },
  components: { PopUpDialog }
}
</script>

<style lang="scss">
.dialog-card {
  width: 700px;
  max-width: 80vw;
}

.flex-grow-2 {
  flex-grow: 2;
}

.CodeMirror {
  border: 1px solid #eee;
  height: 100%;
}
</style>
