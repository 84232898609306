import { createStore } from 'vuex'
import { savedQueries as savedQuery, responeTables as tableList, tableCount } from '@/assets/mocks'
import { getRandom } from '@/utils'

export default createStore({
  state: {
    savedQueries: [],
    selectedQuery: {},
    queryResult: null
  },
  mutations: {
    setSavedQueries (state, savedQueries) {
      state.savedQueries = savedQueries
    },
    updateSavedQueries (state, saveQuery) {
      state.savedQueries.push(saveQuery)
    },
    setSelectedQuery (state, selectedQuery) {
      state.selectedQuery = selectedQuery
    },
    setQueryResult (state, queryResult) {
      const columns = queryResult.metadata.columns.reduce((acc, column) => [...acc, {
        name: column.name.toLocaleLowerCase(),
        label: column.name.toUpperCase(),
        field: column.name,
        align: 'left',
        sortable: true
      }], [])

      state.queryResult = {
        title: queryResult?.metadata?.name,
        columns,
        rows: queryResult.data
      }
    }
  },
  actions: {
    getSavedQueries ({ commit }) {
      commit('setSavedQueries', savedQuery)
    },
    addSavedQuery ({ commit }, { saveQuery }) {
      commit('updateSavedQueries', saveQuery)
    },
    getQueryResult ({ commit }, { query }) {
      const randomNumber = getRandom(tableCount)
      commit('setSelectedQuery', {
        query: query,
        resultId: randomNumber
      })
      commit('setQueryResult', tableList[randomNumber])
    },
    getSelectedQueryResult ({ commit }, { selectQuery }) {
      commit('setSelectedQuery', selectQuery)
      commit('setQueryResult', tableList[selectQuery.resultId])
    }
  }
})
