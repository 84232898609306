<template>
  <div class="row window-height">
    <div class="col-md-4 q-pa-md full-height">
      <q-card bordered class="full-height column no-wrap">
        <q-card-section>
          <div class="text-h6">Query Section</div>
        </q-card-section>
        <QueryTabs />
      </q-card>
    </div>
    <div class="col q-pa-md full-height">
      <q-card bordered class="full-height">
        <QueryTable v-if="queryResult" :title="queryResult.title" :columns="queryResult.columns"
          :rows="queryResult.rows" />
        <div v-else class="q-pa-md text-h6 text-center text-grey-8">Run query to view data.</div>
      </q-card>
    </div>
  </div>
</template>

<script>
import QueryTabs from '@/components/QueryTabs'
import QueryTable from '@/components/QueryTable'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'HomePage',
  components: {
    QueryTabs,
    QueryTable
  },
  computed: {
    ...mapState({
      queryResult: 'queryResult'
    })
  },
  created () {
    this.getSavedQueries()
  },
  methods: {
    ...mapActions({
      getSavedQueries: 'getSavedQueries'
    })
  }
}
</script>
