<template>
  <q-tabs v-model="currentTab" dense class="text-dark" active-color="primary" indicator-color="primary" align="justify">
    <q-tab :ripple="false" v-for="tab in tabInfo" :key="tab.name" :name="tab.name" :label="tab.label" />
  </q-tabs>
  <q-separator />

  <q-tab-panels class="full-height" v-model="currentTab" animated>
    <q-tab-panel class="q-pa-none" v-for="tab in tabInfo" :key="tab.name" :name="tab.name">
      <component :is="tab.type"></component>
    </q-tab-panel>
  </q-tab-panels>
</template>

<script>
import { markRaw } from 'vue'
import NewQuery from '@/components/queryTabs/NewQuery'
import SavedQuery from '@/components/queryTabs/SavedQuery'

export default {
  name: 'QueryTabs',
  components: {
    NewQuery: markRaw(NewQuery),
    SavedQuery: markRaw(SavedQuery)
  },
  data () {
    return {
      tabInfo: [
        {
          name: 'savedQuery',
          label: 'Saved',
          type: SavedQuery
        },
        {
          name: 'newQuery',
          label: 'Query',
          type: NewQuery
        }
      ],
      currentTab: ''
    }
  },
  beforeMount () {
    this.currentTab = this.tabInfo[0].name
  }
}
</script>
